import React from 'react';
import {Link} from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import { BsWindows, BsApple } from 'react-icons/bs';
import { motion } from "framer-motion"

export default function Download() {
    return (
    <HomeLayout>
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main>
        <section class="bg-white">
            <div class="text-center pt-2 pb-10 md:pt-10 md:pb-10">
                <h2 class="text-4xl lg:text-5xl leading-normal font-extrabold text-neutral-900">Download Chirphost</h2>
            </div>
        </section>
        <section className='container text-center mx-auto px-6 lg:px-12 2xl:px-10 mb-14'>
            <div className='bg-main-alt p-10 lg:p-14 rounded-2xl'>
                <div className='flex flex-col lg:flex-row'>
                    <div className='w-2/2 lg:w-1/2'>
                        <h4 className='heading'>Chirphost for Desktop</h4>
                        <img src='/images/main-banner.png' className='w-2/2 lg:w-10/12 mx-auto object-contain mt-10'/>
                    </div>
                    <div className='w-2/2 lg:w-1/2 pt-10 pb-0 px-0 lg:px-10 lg:pb-10 items-center'>
                        <h2 class="text-3xl leading-normal font-semibold text-neutral-900">For Windows and Mac</h2>
                        <p className='text-neutral-700 leading-loose text-md mt-3 mb-10'>By downloading Chirphost, you accept our <Link to={"/"} className="text-blue-600 font-medium underline">Privacy Policy</Link> and <Link to={"/"} className="text-blue-600 font-medium underline">Terms and Conditions</Link></p>
                        <div className='flex flex-col w-2/2 lg:w-2/3 mx-auto space-y-4 justify-center'>
                        <button class="btn-main btn-download !flex items-center !py-5 !px-3 mx-auto justify-center w-full !font-semibold hover:!bg-black hover:!text-white" type="button">Download for Windows <BsWindows className='ml-3'/></button>
                        <button class="btn-main btn-download !flex items-center !py-5 !px-3 mx-auto justify-center w-full !font-semibold hover:!bg-black hover:!text-white" type="button">Download for Mac <BsApple className='ml-3'/></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='container text-center mx-auto px-6 lg:px-12 2xl:px-10 mb-14'>
            <div className='bg-main-alt p-10 lg:p-14 rounded-2xl'>
                <div className='flex flex-col lg:flex-row'>
                    <div className='w-2/2 lg:w-1/2 py-0 px-10 pb-10 flex flex-col justify-center space-y-10'>
                        <h4 className='heading'>Chirphost for Mobile</h4>
                        <div className='flex flex-col md:flex-row mx-auto space-x-0 md:space-x-5 space-y-5 md:space-y-0 justify-center px-0 container'>
                        <Link className='p-1 bg-black rounded-lg w-2/2 md:w-1/2 flex items-center'><img className='w-full object-contain' src='/images/app-store.png'/></Link>
                        <Link className='p-1 bg-black rounded-lg w-2/2 md:w-1/2 flex items-center'><img className='w-full object-contain' src='/images/google-play.png'/></Link>
                        </div>
                        <p className='text-neutral-700 leading-loose text-md mt-3 mb-10'>Available for Android and iPhone</p>
                    </div>
                    <div className='w-2/2 lg:w-1/2'>
                        <div className='w-2/2 lg:w-10/12 mx-auto flex flex-row space-x-4'>
                            <div className='w-1/2'>
                                <img src='/images/mobile.png' className='w-full mx-auto object-contain'/>
                            </div>
                            <div className='w-1/2'>
                                <img src='/images/mobile2.png' className='w-full mx-auto object-contain'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
    </motion.div>
    </HomeLayout>
    )
}