import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Features from './pages/Features';
import Download from './pages/Download';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import { AnimatePresence } from "framer-motion";

function App() {
  return (
    <AnimatePresence>
      <Router>
        <Routes>
          <Route path="/" index element={<Home/>} />
          <Route path="/download" element={<Download/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/features" element={<Features/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/privacy" element={<Privacy/>} />
        </Routes>
      </Router>
    </AnimatePresence>
  );
}

export default App;
